import React, { useState, useEffect } from "react";
import "./welcome.css";
import Cookies from "universal-cookie";
import Popup from "reactjs-popup";
import axios from "axios";
import md5 from "md5";

const Welcome = () => {
  const cookies = new Cookies();
  const [passwordA, setPasswordA] = useState("");
  const [passwordB, setPasswordB] = useState("");
  const [name, setName] = useState("");
  const [nemail, setNemail] = useState("");
  const [nrescity, setNrescity] = useState("");
  const [naddress, setNaddress] = useState("");
  const [ncelphone, setNcelphone] = useState("");
  const [nidplace, setNidplace] = useState("");
  const [myfile, setMyfile] = useState([]);
  const [myfile2, setMyfile2] = useState([]);
  const [uploadname, setUploadname] = useState("");

  const bypass = [
    {
      NumeroCuenta: "",
      Banco: "",
      TipoCuenta: "",
      NombreTitular: "",
      TipoTitular: "",
      DocumentoTItular: "",
    },
  ];

  const [userpaysheetdata, setUserpaysheetdata] = useState(bypass);
  const [naccountnum, setNaccountnum] = useState("");
  const [nbankname, setNbankname] = useState("");
  const [nbankacctype, setNbankacctype] = useState("");
  const [nbankaccname, setNbankaccname] = useState("");
  const [nbankaccdoctype, setNbankaccdoctype] = useState("");
  const [nbankaccdocnum, setNbankaccdocnum] = useState("");
  const idnum = cookies.get("idnum");
  const email = cookies.get("email");
  const perms = cookies.get("perms");
  const address = cookies.get("address");
  const idplace = cookies.get("idplace");
  const rescity = cookies.get("rescity");
  const celphone = cookies.get("celphone");

  useEffect(() => {
    if (cookies.get("perms") === "therapist") {
      let pieces = cookies.get("name").split(" ");
      if (pieces[0] === "DOM.") {
        if (pieces.length > 4)
          setName(pieces[2] + " " + pieces[3] + " " + pieces[4]);
        else {
          setName(pieces[2] + " " + pieces[3]);
        }
      } else {
        if (pieces.length > 4)
          setName(pieces[1] + " " + pieces[2] + " " + pieces[3]);
        else {
          setName(pieces[1] + " " + pieces[2]);
        }
      }
    } else {
      setName(cookies.get("name"));
    }
    fetchallData();
    setNaddress(address);
    setNrescity(rescity);
    setNidplace(idplace);
    setNcelphone(celphone);
    setNemail(email);
  }, [idnum]);

  const UploadFile = async () => {
    const data = new FormData();
    data.append("userfile", myfile);
    const ufile = {
      NombreTerapeuta: cookies.get("name"),
      NombreArchivo: uploadname,
    };
    await axios
      .post(
        "https://portal-colaboradores.riie.com.co/server/upload_files.php",
        data
      )
      .then((response) => {
        setUploadname(response.data);
        alert(response.data);
      });
    await axios
      .post(
        "https://portal-colaboradores.riie.com.co/server/files_crud.php",
        ufile
      )
      .then((response) => {
        alert(response.data.msj);
      });
  };

  const UploadFile2 = async () => {
    const data = new FormData();
    data.append("userfile", myfile2);
    const ufile = {
      NombreTerapeuta: cookies.get("name"),
      NombreArchivo: uploadname,
    };
    await axios
      .post(
        "https://portal-colaboradores.riie.com.co/server/upload_files.php",
        data
      )
      .then((response) => {
        setUploadname(response.data);
        alert(response.data);
      });
    await axios
      .post(
        "https://portal-colaboradores.riie.com.co/server/files_crud.php",
        ufile
      )
      .then((response) => {
        alert(response.data.msj);
      });
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/api_portal.php",
        {
          params: {
            Table: "users_paysheet_data",
            Ident: idnum,
          },
        }
      );
      if (res.data.length > 0) {
        setUserpaysheetdata(res.data);
      } else {
        setUserpaysheetdata(bypass);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateAccInfo = async () => {
    try {
      await axios.put(
        "https://portal-colaboradores.riie.com.co/api_portal.php",
        null,
        {
          params: {
            Request: "UpdateAccInfo",
            NAccountNum: naccountnum,
            NBank: nbankname,
            NAccountType: nbankacctype,
            NBankAccName: nbankaccname,
            NBankAccDocType: nbankaccdoctype,
            NBankAccDocNum: nbankaccdocnum,
            Identif: cookies.get("idnum"),
          },
        }
      );
      alert("Actualización exitosa");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateEmail = async () => {
    try {
      await axios.put(
        "https://portal-colaboradores.riie.com.co/server/users_basic_crud.php",
        null,
        {
          params: {
            Request: "UpdateAll",
            NEmail: nemail,
            NRescity: nrescity,
            NAddress: naddress,
            NCelphone: ncelphone,
            NIDPlace: nidplace,
            Document: cookies.get("idnum"),
          },
        }
      );
      alert("Actualización exitosa");
    } catch (err) {
      console.log(err);
    }
    setPasswordA("");
    setPasswordB("");
  };

  const UpdatePassword = async () => {
    if (passwordA === passwordB) {
      try {
        await axios.put(
          "https://portal-colaboradores.riie.com.co/api_portal.php",
          null,
          {
            params: {
              Request: "UpdatePassword",
              Table: "users_basic_data",
              NPassword: md5(passwordA),
              User: cookies.get("user"),
            },
          }
        );
        alert("Actualización exitosa");
      } catch (err) {
        console.log(err);
      }
      setPasswordA("");
      setPasswordB("");
    } else {
      alert("las contraseñas no coinciden");
    }
  };

  return (
    <div>
      <h1 className="welcome_msg">
        Bienvenido al portal de colaboradores de RiiE.
      </h1>
      <div className="menu">
        <h2>Información basica del usuario:</h2>
        <label>
          <b>Nombre de usuario:</b> {name}
        </label>
        <label>
          <b>Número de documento:</b> {idnum}
        </label>
        <label>
          <b>Lugar de expedición del documento:</b> {idplace}
        </label>
        <label>
          <b>Dirección:</b> {address}
        </label>
        <label>
          <b>Ciudad de residencia:</b> {rescity}
        </label>
        <label>
          <b>Número de celular:</b> {celphone}
        </label>
        <label>
          <b>Correo electronico:</b> {email}
        </label>
        <label>
          <b>Tipo de usuario:</b> {perms}
        </label>
        <Popup trigger={<button>Actualizar datos basicos</button>}>
          <div>
            <label>
              Ingrese su dirección:{" "}
              <input
                value={naddress}
                onChange={(e) => setNaddress(e.target.value)}
              />
            </label>
            <label>
              Ingrese su ciudad de residencia:{" "}
              <input
                value={nrescity}
                onChange={(e) => setNrescity(e.target.value)}
              />
            </label>

            <label>
              Ingrese la ciudad de expedición de su documento:{" "}
              <input
                value={nidplace}
                onChange={(e) => setNidplace(e.target.value)}
              />
            </label>

            <label>
              Ingrese su número de celular:{" "}
              <input
                value={ncelphone}
                onChange={(e) => setNcelphone(e.target.value)}
              />
            </label>

            <label>
              Ingrese su correo electronico:{" "}
              <input
                value={nemail}
                onChange={(e) => setNemail(e.target.value)}
              />
            </label>
            <button onClick={UpdateEmail}>Enviar</button>
          </div>
        </Popup>
      </div>
      <div className="menu">
        <h2>Información de cuenta</h2>
        <label>
          <b>Número de cuenta:</b> {userpaysheetdata[0].NumeroCuenta}
        </label>

        <label>
          <b>Banco:</b> {userpaysheetdata[0].Banco}
        </label>

        <label>
          <b>Tipo de cuenta:</b> {userpaysheetdata[0].TipoCuenta}
        </label>

        <label>
          <b>Nombre del titular de la cuenta:</b>{" "}
          {userpaysheetdata[0].NombreTitular}
        </label>

        <label>
          <b>Tipo de documento del titular de la cuenta:</b>{" "}
          {userpaysheetdata[0].TipoTitular}
        </label>

        <label>
          <b>Número de documento del titular de la cuenta:</b>{" "}
          {userpaysheetdata[0].DocumentoTItular}
        </label>
        <Popup trigger={<button>Actualizar datos de cuenta</button>} modal>
          <div>
            <label>
              Número de cuenta:{" "}
              <input
                value={naccountnum}
                onChange={(e) => setNaccountnum(e.target.value)}
              />
            </label>

            <label>
              Banco:{" "}
              <input
                value={nbankname}
                onChange={(e) => setNbankname(e.target.value)}
              />
            </label>

            <label>
              Tipo de cuenta:{" "}
              <input
                value={nbankacctype}
                onChange={(e) => setNbankacctype(e.target.value)}
              />
            </label>
            <label>
              Nombre del titular de la cuenta:{" "}
              <input
                value={nbankaccname}
                onChange={(e) => setNbankaccname(e.target.value)}
              />
            </label>

            <label>
              Tipo de documento del titular de la cuenta:{" "}
              <input
                value={nbankaccdoctype}
                onChange={(e) => setNbankaccdoctype(e.target.value)}
              />
            </label>

            <label>
              Número de documento del titular de la cuenta:{" "}
              <input
                value={nbankaccdocnum}
                onChange={(e) => setNbankaccdocnum(e.target.value)}
              />
            </label>

            <button onClick={UpdateAccInfo}>Enviar</button>
          </div>
        </Popup>
      </div>

      <div className="menu">
        <h2>Sube tu comprobante de seguridad social aqui.</h2>

        <input
          type="file"
          className="test"
          onChange={(e) => setMyfile(e.target.files[0])}
        />

        <button onClick={UploadFile}>Subir</button>
      </div>
      <div className="menu">
        <h2>Sube tu registro de RUT aqui.</h2>

        <input
          type="file"
          className="test"
          onChange={(e) => setMyfile2(e.target.files[0])}
        />

        <button onClick={UploadFile2}>Subir</button>
      </div>

      <Popup trigger={<button>Actualizar contraseña</button>}>
        <div>
          <label>
            Ingrese la nueva contraseña:{" "}
            <input
              value={passwordA}
              onChange={(e) => setPasswordA(e.target.value)}
            />
          </label>

          <label>
            Ingrese la nueva contraseña de nuevo:{" "}
            <input
              value={passwordB}
              onChange={(e) => setPasswordB(e.target.value)}
            />
          </label>

          <button onClick={UpdatePassword}>Enviar</button>
        </div>
      </Popup>
    </div>
  );
};

export default Welcome;
