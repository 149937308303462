import axios from "axios";
import React, { useState, useEffect } from "react";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import md5 from "md5";

const TherapistsCrud = () => {
  const [dbtherapists, setDBTherapists] = useState([]);
  const [tabledata, setTabledata] = useState([]);
  const [input, setInput] = useState("");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);
  const [name, setName] = useState("");
  const [idnumber, setIDNumber] = useState("");
  const [therdoc, setTherdoc] = useState("");
  const [type, setType] = useState("");
  const [uname, setUName] = useState("");
  const [uidnumber, setUIDNumber] = useState("");
  const [utherdoc, setUTherdoc] = useState("");
  const [utype, setUType] = useState("");

  const Filtering = (value) => {
    setInput(value);
    Filter(value);
  };

  const NewUser = async (e) => {
    e.preventDefault();
    if (name === "" || idnumber === "" || therdoc === "" || type === "") {
      alert("Por favor introduzca todos los datos");
    } else {
      try {
        await axios.post(
          "https://portal-colaboradores.riie.com.co/server/therapists_crud.php",
          null,
          {
            params: {
              Document: therdoc,
              Name: name.toUpperCase(),
              IDNumber: idnumber,
              Perms: type,
              Passwords: md5(therdoc),
            },
          }
        );
        alert("Envio exitoso");
        fetchallData();
        setInput("");
        setName("");
        setIDNumber("");
        setTherdoc("");
        setType("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const Next = () => {
    if (end > tabledata.length) {
      alert("No hay mas registros que mostrar");
    } else {
      setStart(start + 20);
      setEnd(end + 20);
    }
  };

  const Previous = () => {
    if (start === 0) {
      alert("No hay datos anteriores para mostrar");
    } else {
      setStart(start - 20);
      setEnd(end - 20);
    }
  };

  const Filter = (filterword) => {
    var busqueda = dbtherapists.filter((element) => {
      if (
        element.Username.toString().toUpperCase().includes(filterword) ||
        element.Username.toString().toLowerCase().includes(filterword) ||
        element.IDNum.toString().toUpperCase().includes(filterword) ||
        element.IDNum.toString().toLowerCase().includes(filterword)
      ) {
        return element;
      } else {
        return "";
      }
    });
    setTabledata(busqueda);
    setStart(0);
    setEnd(20);
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/api_portal.php",
        {
          params: { Table: "users_basic_data" },
        }
      );
      setDBTherapists(res.data);
      setTabledata(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteRegister = async (id) => {
    try {
      await axios.delete(
        "https://portal-colaboradores.riie.com.co/server/therapists_crud.php",
        { params: { IDNumber: id } }
      );
      alert("Eliminación exitosa");
      fetchallData();
      setInput("");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateUser = async (id) => {
    if (uname === "" || uidnumber === "" || utherdoc === "" || utype === "") {
      alert("Por favor ingrese todos los datos.");
    } else {
      try {
        await axios.put(
          "https://portal-colaboradores.riie.com.co/server/therapists_crud.php",
          null,
          {
            params: {
              Document: utherdoc,
              Name: uname.toUpperCase(),
              NIDNumber: uidnumber,
              Perms: utype,
              Passwords: md5(utherdoc),
              IDNumber: id,
            },
          }
        );
        alert("Actualización exitosa");
        fetchallData();
        setInput("");
        setUName("");
        setUIDNumber("");
        setUTherdoc("");
        setUType("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const GetUserType = () => {
    const data = document.getElementById("Type").value;
    setType(data);
  };

  const GetUserUType = () => {
    const data = document.getElementById("UType").value;
    setUType(data);
  };

  useEffect(() => {
    fetchallData();
  }, []);

  return (
    <>
      <div className="control_panel">
        <div className="filter">
          <label>
            Filtro por nombre de terapeuta:{" "}
            <input value={input} onChange={(e) => Filtering(e.target.value)} />
          </label>
        </div>
        <div className="buttons">
          <button onClick={Previous}>Anterior</button>
          <button onClick={Next}>Siguiente</button>
          <Popup trigger={<button>Añadir terapeuta</button>}>
            <div>
              <label>Nombre del terapeuta:</label>
              <input value={name} onChange={(e) => setName(e.target.value)} />
              <label>Codigo del terapeuta:</label>
              <input
                value={idnumber}
                onChange={(e) => setIDNumber(e.target.value)}
              />
              <label>Documento del terapeuta:</label>
              <input
                value={therdoc}
                onChange={(e) => setTherdoc(e.target.value)}
              />
              <label>Tipo de usuario:</label>
              <select id="Type" defaultValue="" onChange={GetUserType}>
                <option value="">Seleccione el tipo de usuario</option>
                <option value="therapist">Terapeuta</option>
                <option value="supervisor">Supervisor</option>
                <option value="admin">Administrador</option>
                <option value="office">Administrativo</option>
              </select>
              <button onClick={NewUser}>Enviar</button>
            </div>
          </Popup>
        </div>
      </div>
      <div className="table_container">
        <table className="tab_content">
          <thead>
            <tr>
              <th>Nombre del terapeuta</th>
              <th>Codigo medifolios</th>
              <th>Número de documento</th>
              <th>Permisos</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {tabledata.slice(start, end).map((therapist, key) => (
              <tr key={key}>
                <td className="table-data">{therapist.Username}</td>
                <td className="table-data">{therapist.UserCode}</td>
                <td className="table-data">{therapist.IDNum}</td>
                <td className="table-data">{therapist.Perms}</td>
                <td className="table-data">
                  <button
                    className="del-button"
                    onClick={() => {
                      DeleteRegister(therapist.UserCode);
                    }}
                  >
                    Borrar
                  </button>
                  <label> </label>
                  <Popup
                    trigger={<button className="upd-button">Actualizar</button>}
                  >
                    <div>
                      <label>Nombre del terapeuta:</label>
                      <input
                        value={uname}
                        onChange={(e) => setUName(e.target.value)}
                      />
                      <label>Codigo del terapeuta:</label>
                      <input
                        value={uidnumber}
                        onChange={(e) => setUIDNumber(e.target.value)}
                      />
                      <label>Documento del terapeuta:</label>
                      <input
                        value={utherdoc}
                        onChange={(e) => setUTherdoc(e.target.value)}
                      />
                      <label>Tipo de usuario:</label>
                      <select
                        id="UType"
                        defaultValue=""
                        onChange={GetUserUType}
                      >
                        <option value="">Seleccione el tipo de usuario</option>
                        <option value="therapist">Terapeuta</option>
                        <option value="supervisor">Supervisor</option>
                        <option value="admin">Administrador</option>
                      </select>
                      <button
                        onClick={() => {
                          UpdateUser(therapist.UserCode);
                        }}
                      >
                        Enviar
                      </button>
                    </div>
                  </Popup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TherapistsCrud;
