import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import AutoInput from "./autosuggest/autosuggest";
import Popup from "reactjs-popup";
import "./news.css";

const News = () => {
  const [servicio, setServicio] = useState("");
  const [novedad, setNovedad] = useState("");
  const [observacion, setObservacion] = useState("");
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const [PatientData, setPatientData] = useState("");
  const [filtroestado, setFiltroestado] = useState("");
  const [tabledata, setTabledata] = useState([]);

  const GetPatientData = (data) => {
    setPatientData(data);
  };

  useEffect(() => {
    fetchallData();
  }, [filtroestado]);

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/server/news_crud.php",
        {
          params: {
            Perms: cookies.get("perms"),
            Terapeuta: cookies.get("name"),
          },
        }
      );
      var formatteddata = res.data.map((element) => {
        var botonterapeuta = true;
        var botonrevision = false;
        if (element.Estado == "PENDIENTE") {
          botonterapeuta = false;
          botonrevision = true;
        } else if (element.Estado == "EN REVISION") {
          botonterapeuta = true;
          botonrevision = false;
        } else if (element.Estado == "NOVEDAD SOLUCIONADA") {
          botonterapeuta = true;
          botonrevision = true;
        }

        return {
          Terapeuta: element.Terapeuta,
          Paciente: element.Paciente,
          Documento: element.Documento,
          Fecha: element.Fecha,
          FechaSesion: element.FechaSesion,
          Servicio: element.Servicio,
          Novedad: element.Novedad,
          Estado: element.Estado,
          Observaciones: element.Observaciones,
          BotonA: botonterapeuta,
          BotonB: botonrevision,
        };
      });
      var filtereddata = formatteddata.filter((element) => {
        if (filtroestado === "") {
          return element;
        } else if (
          filtroestado === "NOVEDAD SOLUCIONADA" &&
          element.Estado === "NOVEDAD SOLUCIONADA"
        ) {
          return element;
        } else if (
          filtroestado === "EN REVISION" &&
          element.Estado === "EN REVISION"
        ) {
          return element;
        } else if (
          filtroestado === "PENDIENTE" &&
          element.Estado === "PENDIENTE"
        ) {
          return element;
        }
      });
      setData(filtereddata.reverse());
      setTabledata(filtereddata);
    } catch (err) {
      console.log(err);
    }
  };

  const SelectHandler = (selector) => {
    const data = document.getElementById(selector).value;
    if (selector === "Servicio") {
      setServicio(data);
    } else if (selector === "Novedad") {
      setNovedad(data);
    }
  };

  const onChange = (e) => {
    setObservacion(e.target.value);
  };

  const AddNew = async (e) => {
    e.preventDefault();
    if (PatientData === "" || servicio === "" || novedad === "") {
      alert("Introduzca todos los datos");
    } else {
      try {
        await axios.post(
          "https://portal-colaboradores.riie.com.co/server/news_crud.php",
          null,
          {
            params: {
              Terapeuta: cookies.get("name"),
              Paciente: PatientData.Name,
              Documento: PatientData.IDNumber,
              Servicio: servicio,
              Novedad: novedad,
            },
          }
        );
        fetchallData();
        alert("Envio exitoso");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const Filtering = (filterword) => {
    var busqueda = data.filter((element) => {
      if (
        element.Terapeuta.toString().toUpperCase().includes(filterword) ||
        element.Terapeuta.toString().toLowerCase().includes(filterword)
      ) {
        return element;
      } else {
        return "";
      }
    });
    setTabledata(busqueda);
  };

  const UpdateNews = async (id, date) => {
    try {
      await axios.put(
        "https://portal-colaboradores.riie.com.co/server/news_crud.php",
        null,
        {
          params: {
            Perms: cookies.get("perms"),
            Documento: id,
            Fecha: date,
            Observaciones: observacion,
          },
        }
      );
      alert("Actualización exitosa");
      fetchallData();
    } catch (err) {
      console.log(err);
    }
    setObservacion("");
  };

  const Verify = async (id, date) => {
    try {
      await axios.put(
        "https://portal-colaboradores.riie.com.co/server/news_crud.php",
        null,
        {
          params: {
            Perms: cookies.get("perms"),
            Documento: id,
            Fecha: date,
          },
        }
      );
      alert("Actualización exitosa");
      fetchallData();
    } catch (err) {
      console.log(err);
    }
  };

  const getRB = (e) => {
    setFiltroestado(e.target.value);
  };

  if (
    cookies.get("perms") === "supervisor" ||
    cookies.get("perms") === "therapist"
  ) {
    if (servicio === "") {
      return (
        <>
          <h1>Reportar novedades</h1>
          <label>Servicio: </label>
          <select id="Servicio" onChange={() => SelectHandler("Servicio")}>
            <option hidden>Seleccione el servicio que desea reportar</option>
            <option>Sistema de firmas de RiiE</option>
            <option>Sistema de evoluciones de Medifolios</option>
          </select>
          <br />
          <label>Novedad: </label>
          <select id="Novedad" onChange={() => SelectHandler("Novedad")}>
            <option hidden>Seleccione el tipo de novedad presentada</option>
          </select>
          <br />
          <AutoInput
            inputname="Paciente:"
            inputdata="Nombre o documento del paciente:"
            minlength={3}
            GetData={GetPatientData}
          />
          <br />
          <button onClick={AddNew}>Enviar</button>
          <div className="table_container">
            <table className="tab_content">
              <thead>
                <tr>
                  <th>Terapeuta</th>
                  <th>Paciente</th>
                  <th>Documento paciente</th>
                  <th>Fecha novedad</th>
                  <th>Fecha sesion</th>
                  <th>Servicio</th>
                  <th>Novedad</th>
                  <th>Accion</th>
                  <th>Observaciones</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {data.map((novedad, key) => (
                  <tr key={key}>
                    <td>{novedad.Terapeuta}</td>
                    <td>{novedad.Paciente}</td>
                    <td>{novedad.Documento}</td>
                    <td>{novedad.Fecha}</td>
                    <td>{novedad.FechaSesion}</td>
                    <td>{novedad.Servicio}</td>
                    <td>{novedad.Novedad}</td>
                    <td>
                      <Popup
                        trigger={
                          <button disabled={novedad.BotonA}>
                            Reportar novedad solucionada
                          </button>
                        }
                      >
                        <label>
                          Describa la solución brindada a la novedad (incluyendo
                          fechas especificas de ser necesario):
                        </label>
                        <br />
                        <input
                          type="text"
                          placeholder="Ingrese sus observaciones"
                          onChange={onChange}
                        />
                        <button
                          onClick={() =>
                            UpdateNews(novedad.Documento, novedad.Fecha)
                          }
                        >
                          Enviar
                        </button>
                      </Popup>
                    </td>
                    <td>{novedad.Observaciones}</td>
                    <td className={novedad.Estado}>{novedad.Estado}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    } else if (servicio === "Sistema de firmas de RiiE") {
      return (
        <>
          <h1>Reportar novedades</h1>
          <label>Servicio: </label>
          <select id="Servicio" onChange={() => SelectHandler("Servicio")}>
            <option hidden>Seleccione el servicio que desea reportar</option>
            <option>Sistema de firmas de RiiE</option>
            <option>Sistema de evoluciones de Medifolios</option>
          </select>
          <br />
          <label>Novedad: </label>
          <select id="Novedad" onChange={() => SelectHandler("Novedad")}>
            <option hidden>Seleccione el tipo de novedad presentada</option>
            <option>
              El sistema no me permite enviar la firma o aparece error
            </option>
            <option>El sistema no carga</option>
            <option>La firma no se registro durante el dia de la sesión</option>
            <option>Equivocación al momento de realizar el registro</option>
          </select>
          <br />
          <AutoInput
            inputname="Paciente:"
            inputdata="Nombre o documento del paciente:"
            minlength={3}
            GetData={GetPatientData}
          />
          <br />
          <button onClick={AddNew}>Enviar</button>
          <div className="table_container">
            <table className="tab_content">
              <thead>
                <tr>
                  <th>Terapeuta</th>
                  <th>Paciente</th>
                  <th>Documento paciente</th>
                  <th>Fecha novedad</th>
                  <th>Fecha sesion</th>
                  <th>Servicio</th>
                  <th>Novedad</th>
                  <th>Acción</th>
                  <th>Observaciones</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {data.map((novedad, key) => (
                  <tr key={key}>
                    <td>{novedad.Terapeuta}</td>
                    <td>{novedad.Paciente}</td>
                    <td>{novedad.Documento}</td>
                    <td>{novedad.Fecha}</td>
                    <td>{novedad.FechaSesion}</td>
                    <td>{novedad.Servicio}</td>
                    <td>{novedad.Novedad}</td>
                    <td>
                      <Popup
                        trigger={
                          <button disabled={novedad.BotonA}>
                            Reportar novedad solucionada
                          </button>
                        }
                      >
                        <label>
                          Describa la solución brindada a la novedad (incluyendo
                          fechas especificas de ser necesario):
                        </label>
                        <br />
                        <input
                          type="text"
                          placeholder="Ingrese sus observaciones"
                          onChange={onChange}
                        />
                        <button
                          onClick={() =>
                            UpdateNews(novedad.Documento, novedad.Fecha)
                          }
                        >
                          Enviar
                        </button>
                      </Popup>
                    </td>
                    <td>{novedad.Observaciones}</td>
                    <td className={novedad.Estado}>{novedad.Estado}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    } else if (servicio === "Sistema de evoluciones de Medifolios") {
      return (
        <>
          <h1>Reportar novedades</h1>
          <label>Servicio: </label>
          <select id="Servicio" onChange={() => SelectHandler("Servicio")}>
            <option hidden>Seleccione el servicio que desea reportar</option>
            <option>Sistema de firmas de RiiE</option>
            <option>Sistema de evoluciones de Medifolios</option>
          </select>
          <br />
          <label>Novedad: </label>
          <select id="Novedad" onChange={() => SelectHandler("Novedad")}>
            <option hidden>Seleccione el tipo de novedad presentada</option>
            <option>La evolución no se registro el dia de la sesión</option>
            <option>
              El sistema de medifolios se encuentra fuera de servicio
            </option>
            <option>Equivocación al momento de realizar el registro</option>
          </select>
          <br />
          <label>Observaciones: </label>
          <br />
          <AutoInput
            inputname="Paciente:"
            inputdata="Nombre o documento del paciente:"
            minlength={3}
            GetData={GetPatientData}
          />
          <br />
          <button onClick={AddNew}>Enviar</button>
          <div className="table_container">
            <table className="tab_content">
              <thead>
                <tr>
                  <th>Terapeuta</th>
                  <th>Paciente</th>
                  <th>Documento paciente</th>
                  <th>Fecha novedad</th>
                  <th>Fecha sesion</th>
                  <th>Servicio</th>
                  <th>Novedad</th>
                  <th>Acción</th>
                  <th>Observaciones</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {data.map((novedad, key) => (
                  <tr key={key}>
                    <td>{novedad.Terapeuta}</td>
                    <td>{novedad.Paciente}</td>
                    <td>{novedad.Documento}</td>
                    <td>{novedad.Fecha}</td>
                    <td>{novedad.FechaSesion}</td>
                    <td>{novedad.Servicio}</td>
                    <td>{novedad.Novedad}</td>
                    <td>
                      <Popup
                        trigger={
                          <button disabled={novedad.BotonA}>
                            Reportar novedad solucionada
                          </button>
                        }
                      >
                        <label>
                          Describa la solución brindada a la novedad (incluyendo
                          fechas especificas de ser necesario):
                        </label>
                        <br />
                        <input
                          type="text"
                          placeholder="Ingrese sus observaciones"
                          onChange={onChange}
                        />
                        <button
                          onClick={() =>
                            UpdateNews(novedad.Documento, novedad.Fecha)
                          }
                        >
                          Enviar
                        </button>
                      </Popup>
                    </td>
                    <td>{novedad.Observaciones}</td>
                    <td className={novedad.Estado}>{novedad.Estado}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  } else if (
    cookies.get("perms") === "admin" ||
    cookies.get("perms") === "news"
  ) {
    return (
      <>
        <h1>Gestionar novedades</h1>
        <label>
          Filtrar por terapeuta:{" "}
          <input onChange={(e) => Filtering(e.target.value)} />
        </label>
        <div onChange={getRB}>
          <input type="radio" name="filter" value="NOVEDAD SOLUCIONADA" />
          <label>Novedades solucionadas</label>
          <br />
          <input type="radio" name="filter" value="EN REVISION" />
          <label>Novedades en revision</label>
          <br />
          <input type="radio" name="filter" value="PENDIENTE" />
          <label>Novedades pendientes</label>
        </div>
        <div className="table_container">
          <table className="tab_content">
            <thead>
              <tr>
                <th>Terapeuta</th>
                <th>Paciente</th>
                <th>Documento paciente</th>
                <th>Fecha novedad</th>
                <th>Fecha sesion</th>
                <th>Servicio</th>
                <th>Novedad</th>
                <th>Observaciones</th>
                <th>Estado</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {tabledata.map((novedad, key) => (
                <tr key={key}>
                  <td>{novedad.Terapeuta}</td>
                  <td>{novedad.Paciente}</td>
                  <td>{novedad.Documento}</td>
                  <td>{novedad.Fecha}</td>
                  <td>{novedad.FechaSesion}</td>
                  <td>{novedad.Servicio}</td>
                  <td>{novedad.Novedad}</td>
                  <td>{novedad.Observaciones}</td>
                  <td className={novedad.Estado}>{novedad.Estado}</td>
                  <td>
                    <button
                      onClick={() => Verify(novedad.Documento, novedad.Fecha)}
                      disabled={novedad.BotonB}
                    >
                      Confirmar novedad solucionada
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
};

export default News;
