import React, { useEffect, useState } from "react";
import "./billing.css";
import axios from "axios";

const Billing = () => {
  const [rawdata, setRawdata] = useState([]);
  const [evoluciones, setEvoluciones] = useState([]);
  const [order, setOrder] = useState([]);
  const [id, setID] = useState("");
  const [month, setMonth] = useState("");
  const [monthreq, setMonthreq] = useState("Marzo");
  const year = "2023";
  const [sorder, SetSOrder] = useState(true);
  const [sevolution, SetSEvolution] = useState(false);
  const [sfirmas, SetSFirmas] = useState(false);
  const [linkzip, setLinkzip] = useState("");
  const [hidden, setHidden] = useState(true);
  const [filename, setFilename] = useState("");
  const [raworder, setRaworder] = useState([]);

  const CheckBoxHandler = (ident) => {
    if (ident === "ev" && sevolution === false) {
      SetSEvolution(true);
    } else if (ident === "ev" && sevolution === true) {
      SetSEvolution(false);
    } else if (ident === "or" && sorder === false) {
      SetSOrder(true);
    } else if (ident === "or" && sorder === true) {
      SetSOrder(false);
    } else if (ident === "fir" && sfirmas === false) {
      SetSFirmas(true);
    } else if (ident === "fir" && sfirmas === true) {
      SetSFirmas(false);
    }
  };

  const Test = () => {
    console.log(raworder);
  };

  const MonthHandler = (e) => {
    setMonth(e.target.value);
    if (e.target.value === "03") {
      setMonthreq("Marzo");
    } else if (e.target.value === "04") {
      setMonthreq("Abril");
    } else if (e.target.value === "05") {
      setMonthreq("Mayo");
    } else if (e.target.value === "06") {
      setMonthreq("Junio");
    }
  };

  const Filtering = async (filterword) => {
    if (month !== "" && year !== "") {
      var auxtest = rawdata.filter((element) => {
        if (element.PatientID === filterword) {
          let pieces = element.Date.split("/");
          if (pieces[1] === month && pieces[2] === year) {
            return element;
          }
        }
      });
      if (sfirmas === true) {
        var test = auxtest;
      } else {
        var test = [];
      }

      if (sevolution === true) {
        var test2 = evoluciones.filter((element) => {
          if (element.PatientID === filterword) {
            let pieces = element.Date.split("-");
            if (pieces[1] === month && pieces[0] === year) {
              return element;
            }
          }
        });
      } else {
        var test2 = [];
      }

      if (sorder === true) {
        var patorder = raworder.filter((element) => {
          if (element.PatientID === filterword) {
            return element;
          }
        });
      } else {
        var patorder = [];
      }

      if (sorder === true) {
        var patorderf = order.filter((element) => {
          if (element.PatientID === filterword) {
            return element;
          }
        });
      } else {
        var patorderf = [];
      }

      var admisiones = patorder.map((element) => {
        return element.Admision;
      });

      var posttest = {
        or: patorder,
        orf: patorderf,
        fir: test,
        adm: [...new Set(admisiones)],
        ev: test2,
        data: auxtest,
      };
      console.log(posttest);

      try {
        await axios
          .post("https://localhost/servertest/billing.php", posttest)
          .then((response) => {
            console.log(response.data);
            setLinkzip(
              "https://portal-colaboradores.riie.com.co/server/temp_files/" +
                response.data
            );
            setHidden(false);
            setFilename(response.data);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Ingrese todos los datos");
    }
  };

  useEffect(() => {
    fetchallData();
  }, [monthreq]);

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/14FhdiUUjyALUqVo7X6-evr2zDFZ6pDSDPJ42-xSZIhk/values:batchGet",
        {
          params: {
            key: "AIzaSyBoE4hP1PbxiPE5BvP7FOJAescmdvjGIC0",
            ranges: "New Form",
          },
        }
      );
      var formateddata = res.data.valueRanges[0].values
        .slice(2, res.data.valueRanges[0].values.length)
        .map((item) => {
          let pieces = item[7].split("/");
          var mes = monthreq;
          var periodo = pieces[1] + "/" + pieces[2];
          return {
            PatientID: item[15],
            Date: item[7],
            Signature: item[4],
            Service: item[12],
            Sessions: item[0],
            Name: item[21],
            Period: periodo,
            Month: mes,
            Entity: item[20],
          };
        });
      setRawdata(formateddata);
    } catch (err) {
      console.log(err);
    }

    try {
      const res = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/1R137PEe9Xp_xn5d0bA0rPezS6AWKE-5gKFIEaXTmc8M/values:batchGet",
        {
          params: {
            key: "AIzaSyBoE4hP1PbxiPE5BvP7FOJAescmdvjGIC0",
            ranges: "Marzo",
          },
        }
      );
      var formateddata = res.data.valueRanges[0].values
        .slice(1, res.data.valueRanges[0].values.length)
        .map((item) => {
          return {
            PatientName: item[0],
            PatientID: item[2],
            Address: item[3],
            Cellphone: item[4],
            Entity: item[5],
            Sexo: item[6],
            Birthdate: item[7],
            Age: item[8],
            Civilstate: item[11],
            Therapist: item[12],
            Date: item[13],
            Scholar: item[14],
            StartHour: item[21],
            ServiceArea: item[22],
            Service: item[23],
            Objetive: item[24],
            Evolution: item[25],
            EndHour: item[26],
            Diagnosis: item[27],
            TherapistID: item[31],
          };
        });

      setEvoluciones(formateddata);
    } catch (err) {
      console.log(err);
    }

    try {
      const res = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/1tIRZq-t1rNAMsIIilPNIt3w0Kwr-VqzpjuNTmyESWkk/values:batchGet",
        {
          params: {
            key: "AIzaSyBoE4hP1PbxiPE5BvP7FOJAescmdvjGIC0",
            ranges: monthreq,
          },
        }
      );
      var formatedorderdata = res.data.valueRanges[0].values
        .slice(2, res.data.valueRanges[0].values.length)
        .map((item) => {
          return {
            PatientID: item[1],
            Service: item[3],
            Admision: item[4],
            Date: item[6],
          };
        });

      var formateddata = res.data.valueRanges[0].values
        .slice(2, res.data.valueRanges[0].values.length)
        .map((item) => {
          var ident = item[5].replace("https://drive.google.com/open?id=", "");
          var ident = ident.replace("https://drive.google.com/file/d/", "");
          var ident = ident.replace("/view?usp=share_link", "");
          return {
            PatientID: item[1],
            Service: item[3],
            Admision: item[4],
            Order: ident,
            Date: item[6],
          };
        });

      setRaworder(formatedorderdata);
      var filtereddata = formateddata.filter((element) => {
        if (element.Order !== "") {
          return element;
        }
      });

      setOrder(filtereddata);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <h1>
        Bienvenido al sistema de consulta de reportes de pacientes de RiiE!
      </h1>
      <label>Ingrese el documento del paciente a consultar: </label>
      <input
        placeholder="Ingrese el documento del paciente:"
        onChange={(e) => setID(e.target.value)}
        value={id}
      />
      <br />
      <label>Seleccione el mes que desea consultar: </label>
      <select
        id="BillingMonth"
        defaultValue=""
        onChange={(e) => MonthHandler(e)}
      >
        <option hidden value="">
          Seleccione un mes
        </option>
        <option value="03">Marzo</option>
        <option value="04">Abril</option>
        <option value="05">Mayo</option>
        <option value="06">Junio</option>
      </select>
      <br />
      <label>Seleccione el año que desea consultar: </label>
      <br />
      <label>Ordenes: </label>
      <input
        type="radio"
        name="ordenes"
        onClick={() => {
          CheckBoxHandler("or");
        }}
        checked={sorder}
      />
      <br />
      <label>Firmas: </label>
      <input
        type="radio"
        name="firmas"
        onClick={() => {
          CheckBoxHandler("fir");
        }}
        checked={sfirmas}
      />
      <br />
      <label>Evoluciones: </label>
      <input
        type="radio"
        name="evoluciones"
        onClick={() => {
          CheckBoxHandler("ev");
        }}
        checked={sevolution}
      />
      <br />
      <button onClick={() => Filtering(id)}>Generar documento</button>
      <button onClick={Test}>Testear mapeo</button>
      <br />
      <label>
        Cuando el nombre del archivo aparezca a continuación pulse sobre el link
        de descarga.
      </label>
      <br />
      <label>{filename}</label>
      <br />
      <a href={linkzip} hidden={hidden}>
        Link de descarga
      </a>      
    </>
  );
};

export default Billing;
