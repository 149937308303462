import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./login.css";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import md5 from "md5";

function Login() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [dbusers, setDBUsers] = useState([]);
  const [badpassword, setBadPassword] = useState(false);
  const [incompletedata, setIncompleteData] = useState(false);
  const cookies = new Cookies();
  const popupstyle = {
    width: "40vw",
    height: "10vh",
    borderRadius: "10px",
    textAlign: "center",
  };

  const Validate = () => {
    if (user !== "") {
      var validation = dbusers.filter((element) => {
        if (element.IDNum === user) {
          return true;
        }
      });

      if (
        validation[0].IDNum === user &&
        validation[0].Passwords === password
      ) {
        cookies.set("user", validation[0].UserCode, { path: "/" });
        cookies.set("name", validation[0].Username, { path: "/" });
        cookies.set("perms", validation[0].Perms, { path: "/" });
        cookies.set("idnum", validation[0].IDNum, { path: "/" });
        cookies.set("email", validation[0].Email, { path: "/" });
        cookies.set("address", validation[0].Address, { path: "/" });
        cookies.set("rescity", validation[0].ResCity, { path: "/" });
        cookies.set("celphone", validation[0].Celphone, { path: "/" });
        cookies.set("idplace", validation[0].IDPlace, { path: "/" });
        window.location.href = "./portal";
      } else {
        setBadPassword(true);
      }
    } else if (user === "" || password === "") {
      setIncompleteData(true);
    }
  };

  useEffect(() => {
    const fetchallData = async () => {
      try {
        const res = await axios.get(
          "https://portal-colaboradores.riie.com.co/api_portal.php",
          { params: { Table: "users_basic_data" } }
        );
        setDBUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchallData();
  }, []);

  return (
    <div className="full-container">
      <div className="login-container">
        <div className="login">
          <label className="title-label">
            Bienvenido/a al portal de colaboradores de RiiE{" "}
          </label>
          <label className="user-label">Ingrese su codigo de usuario:</label>
          <input
            className="user-input"
            type="text"
            onChange={(e) => setUser(e.target.value)}
          />
          <label className="user-label">Ingrese su contraseña:</label>
          <input
            className="user-input"
            type="password"
            onChange={(e) => setPassword(md5(e.target.value))}
          />

          <button onClick={Validate} className="login-button">
            Ingresar
          </button>
        </div>
      </div>

      <Popup
        modal
        open={badpassword}
        onClose={() => {
          setBadPassword(false);
        }}
        contentStyle={popupstyle}
      >
        <label className="popup-label">
          Su contraseña es incorrecta o su usuario no se encuentra registrado.{" "}
        </label>
      </Popup>
      <Popup
        modal
        open={incompletedata}
        onClose={() => {
          setIncompleteData(false);
        }}
        contentStyle={popupstyle}
      >
        <label className="popup-label">
          Por favor ingrese todos los datos.
        </label>
      </Popup>
    </div>
  );
}

export default Login;
