import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/login";
import Portal from "../pages/portal/portal";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/portal" element={<Portal/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
