import React from "react";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import AutoInput from "./autosuggest/autosuggest";

const TherapistInfo = () => {
  const cookies = new Cookies();
  const [signs, setSigns] = useState([]);
  const [dsigns, setDSigns] = useState([]);
  const [idate, setIDate] = useState("2023-01-01");
  const [fdate, setFDate] = useState(new Date());
  const [input, setInput] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);
  const [PatientData, setPatientData] = useState("");

  const GetPatientData = (data) => {
    setPatientData(data);
  };

  const Next = () => {
    if (end > dsigns.length) {
      alert("No hay mas registros que mostrar");
    } else {
      setStart(start + 20);
      setEnd(end + 20);
    }
  };

  const Previous = () => {
    if (start === 0) {
      alert("No hay datos anteriores para mostrar");
    } else {
      setStart(start - 20);
      setEnd(end - 20);
    }
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/server/signatures_crud.php",
        {
          params: {
            Table: "def_signatures",
            Perms: cookies.get("perms"),
            Therapist: cookies.get("user"),
          },
        }
      );
      var datos = res.data.reverse();
      setSigns(datos);
      setDSigns(datos);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchallData();
    document.getElementById("datepicker").valueAsDate = new Date();
    setFDate(document.getElementById("datepicker").value);
  }, []);

  const IDateHandler = (e) => {
    setIDate(e.target.value);
  };

  const FDateHandler = (e) => {
    setFDate(e.target.value);
  };

  const DateFilter = (filtername) => {
    var busqueda = signs.filter((element) => {
      if (
        fdate >= element.DateTime &&
        element.DateTime >= idate &&
        (element.PatientName.toString().toLowerCase().includes(filtername) ||
          element.PatientName.toString().toUpperCase().includes(filtername) ||
          element.TherapistName.toString().toLowerCase().includes(filtername) ||
          element.TherapistName.toString().toUpperCase().includes(filtername) ||
          element.PatientIDNumber.toString()
            .toLowerCase()
            .includes(filtername) ||
          element.PatientIDNumber.toString().toUpperCase().includes(filtername))
      ) {
        return element;
      }
    });
    setDSigns(busqueda);
    setStart(0);
    setEnd(20);
  };

  const ClearFilter = () => {
    setDSigns(signs);
    setIDate("");
    setFDate("");
    setStart(0);
    setEnd(20);
  };

  return (
    <>
      <div className="control_panel">
        <div className="filter">
          <label>
            Busqueda por paciente:{" "}
            <AutoInput
              inputname="Paciente:"
              inputdata="Nombre o documento del paciente:"
              minlength={3}
              GetData={GetPatientData}
            />
          </label>

          <label>
            Fecha inicial:{" "}
            <input value={idate} onChange={IDateHandler} type="date" />
          </label>

          <label>
            {" "}Fecha final:{" "}
            <input id="datepicker" onChange={FDateHandler} type="date" />
          </label>
        </div>
        <div className="buttons">
          <button
            onClick={() => {
              DateFilter(PatientData.Name);
            }}
          >
            Filtrar registros
          </button>
          <button onClick={ClearFilter}>Limpiar filtro</button>
          <button onClick={Previous}>Anterior</button>
          <button onClick={Next}>Siguiente</button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="table_container">
        <table className="tab_content">
          <thead>
            <tr>
              <th>Sesiones</th>
              <th>Fecha del registro</th>
              <th>Fecha de la sesion</th>
              <th>Nombre del paciente</th>
              <th>Servicio</th>
              <th>Nombre del terapeuta</th>
              <th>Codigo del terapeuta</th>
              <th>Sede</th>
            </tr>
          </thead>
          <tbody>
            {dsigns.slice(start, end).map((signtable, key) => (
              <tr key={key}>
                <td className="table-data">{signtable.Sessions}</td>
                <td className="table-data">{signtable.ActualDate}</td>
                <td className="table-data">{signtable.DateTime}</td>
                <td className="table-data">{signtable.PatientName}</td>
                <td className="table-data">{signtable.Service}</td>
                <td className="table-data">{signtable.TherapistName}</td>
                <td className="table-data">{signtable.TherapistIDNumber}</td>
                <td className="table-data">{signtable.Site}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TherapistInfo;
