import axios from "axios";
import React, { useState, useEffect } from "react";
import "./patient_crud.css";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";

const PatientCrud = () => {
  const [dbpatients, setDBPatients] = useState([]);
  const [tabledata, setTabledata] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);
  const [name, setName] = useState("");
  const [idnumber, setIDNumber] = useState("");
  const [uname, setUName] = useState("");
  const [uidnumber, setUIDNumber] = useState("");

  const NewUser = async (e) => {
    e.preventDefault();
    if (name === "" || idnumber === "") {
      alert("Por favor introduzca todos los datos");
    } else {
      try {
        await axios.post(
          "https://portal-colaboradores.riie.com.co/server/patients_crud.php",
          null,
          {
            params: {
              Table: "patients",
              IDNumber: idnumber,
              Name: name,
            },
          }
        );
        alert("Envio exitoso");
        fetchallData();
      } catch (err) {
        console.log(err);
      }
    }
    setName("");
    setIDNumber("");
  };

  const Next = () => {
    if (end > tabledata.length) {
      alert("No hay mas registros que mostrar");
    } else {
      setStart(start + 20);
      setEnd(end + 20);
    }
  };

  const Previous = () => {
    if (start === 0) {
      alert("No hay datos anteriores para mostrar");
    } else {
      setStart(start - 20);
      setEnd(end - 20);
    }
  };

  const Filter = (filterword) => {
    var busqueda = dbpatients.filter((element) => {
      if (
        element.Name.toString().toUpperCase().includes(filterword) ||
        element.Name.toString().toLowerCase().includes(filterword) ||
        element.IDNumber.toString().toUpperCase().includes(filterword) ||
        element.IDNumber.toString().toLowerCase().includes(filterword)
      ) {
        return element;
      } else {
        return "";
      }
    });
    setTabledata(busqueda);
    setStart(0);
    setEnd(20);
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/server/patients_crud.php",
        {
          params: { Table: "patients" },
        }
      );
      setDBPatients(res.data);
      setTabledata(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteRegister = async (id) => {
    try {
      await axios.delete(
        "https://portal-colaboradores.riie.com.co/server/patients_crud.php",
        { params: { Table: "patients", IDNumber: id } }
      );
      alert("Eliminación exitosa");
      fetchallData();
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateUser = async (id) => {
    try {
      await axios.put(
        "https://portal-colaboradores.riie.com.co/server/patients_crud.php",
        null,
        {
          params: {
            Table: "patients",
            NIDNumber: uidnumber,
            OIDNumber: id,
            Name: uname,
          },
        }
      );
      alert("Actualización exitosa");
      fetchallData();
    } catch (err) {
      console.log(err);
    }
    setUName("");
    setUIDNumber("");
  };

  useEffect(() => {
    fetchallData();
  }, []);

  return (
    <>
      <div className="control_panel">
        <div className="filter">
          <label>
            Filtro por nombre de paciente:{" "}
            <input
              onChange={(e) => {
                Filter(e.target.value);
              }}
            />
          </label>
        </div>
        <div className="buttons">
          <button onClick={Previous}>Anterior</button>
          <button onClick={Next}>Siguiente</button>
          <Popup trigger={<button>Añadir paciente</button>}>
            <div>
              <label>Nombre del paciente:</label>
              <input value={name} onChange={(e) => setName(e.target.value)} />
              <label>Documento del paciente:</label>
              <input
                value={idnumber}
                onChange={(e) => setIDNumber(e.target.value)}
              />
              <button onClick={NewUser}>Enviar</button>
            </div>
          </Popup>
        </div>
      </div>
      <div className="table_container">
        <table className="tab_content">
          <thead>
            <tr>
              <th>Nombre del paciente</th>
              <th>Documento del paciente</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {tabledata.slice(start, end).map((patient, key) => (
              <tr key={key}>
                <td className="table-data">{patient.Name}</td>
                <td className="table-data">{patient.IDNumber}</td>
                <td className="table-data">
                  <Popup
                    modal
                    trigger={<button className="del-button">Eliminar</button>}
                  >
                    <label>¿Seguro que desea eliminar este registro?</label>
                    <br />
                    <button
                      onClick={() => {
                        DeleteRegister(patient.IDNumber);
                      }}
                    >
                      Confirmar
                    </button>
                  </Popup>
                  <label> </label>
                  <Popup
                    trigger={<button className="upd-button">Actualizar</button>}
                  >
                    <div>
                      <label>Nombre del paciente:</label>
                      <input
                        value={uname}
                        onChange={(e) => setUName(e.target.value)}
                      />
                      <label>Documento del paciente:</label>
                      <input
                        value={uidnumber}
                        onChange={(e) => setUIDNumber(e.target.value)}
                      />
                      <button
                        onClick={() => {
                          UpdateUser(patient.IDNumber);
                        }}
                      >
                        Enviar
                      </button>
                    </div>
                  </Popup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PatientCrud;
