import React, { useState, useEffect } from "react";
import axios from "axios";
import "./tasks.css";
import Cookies from "universal-cookie";
import Popup from "reactjs-popup";

const Tasks = () => {
  const [data, setData] = useState([]);
  const bypass = [
    { SesionesCompletas: 0, SesionesPendientes: 0, ValorSesionesCompletas: 0 },
  ];
  const [total, setTotal] = useState(bypass);
  const cookies = new Cookies();
  const [month, setMonth] = useState("Septiembre");
  const [news, setNews] = useState("");

  const AddNew = async (patient, document, service, sessiondate) => {
    if (news === "") {
      alert("Introduzca todos los datos");
    } else {
      let correcteddate = sessiondate.split("/");
      var date =
        correcteddate[2] + "/" + correcteddate[1] + "/" + correcteddate[0];
      try {
        await axios.post(
          "https://portal-colaboradores.riie.com.co/server/news_crud.php",
          null,
          {
            params: {
              Terapeuta: cookies.get("name"),
              Paciente: patient,
              Documento: document,
              Servicio: service,
              Novedad: news,
              FechaSesion: date,
            },
          }
        );
        fetchallData();
        alert("Envio exitoso");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const GetNews = () => {
    const data = document.getElementById("Novedad").value;
    setNews(data);
  };

  const GetMonth = () => {
    const data2 = document.getElementById("Mes").value;
    setMonth(data2);
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/1OfTALCGiP-Z9-BeGZ41wTiNqoYnLdD5lHdIMQ4lRnkA/values:batchGet",
        {
          params: {
            key: "AIzaSyBoE4hP1PbxiPE5BvP7FOJAescmdvjGIC0",
            ranges: month + " 2024",
          },
        }
      );
      var datos = res.data.valueRanges[0].values
        .slice(1, res.data.valueRanges[0].values.length)
        .map((element) => {
          if (element[7] === "OK FIRMADO") {
            var BotonA = true;
          } else {
            var BotonA = false;
          }

          if (element[8] === "OK EVOLUCIONADO") {
            var BotonB = true;
          } else {
            var BotonB = false;
          }

          return {
            Fecha: element[0],
            Hora: element[1],
            Terapeuta: element[11],
            Sitio: element[3],
            DocumentoPaciente: element[4],
            Paciente: element[5],
            Admision: element[6],
            Firmado: element[7],
            Evolucionado: element[8],
            Estado: element[9],
            Formato: element[10],
            ID: element[14],
            BotonFirma: BotonA,
            BotonEvolucion: BotonB,
            Pendiente: element[19],
            Completas: element[16],
            CompletasDinero: element[17],
          };
        });
      if (
        cookies.get("perms") === "news" ||
        cookies.get("perms") === "admin" ||
        cookies.get("perms") === "supervisor"
      ) {
        var incompletos = datos.filter((element) => {
          if (
            (element.Firmado !== "OK FIRMADO" ||
              element.Evolucionado !== "OK EVOLUCIONADO" ||
              element.Formato !== "OK FORMATO") &&
            element.Terapeuta !== cookies.get("name") &&
            element.Estado === "CUMPLIDA"
          ) {
            return element;
          }
        });
      } else {
        var incompletos = datos.filter((element) => {
          if (
            (element.Firmado !== "OK FIRMADO" ||
              element.Evolucionado !== "OK EVOLUCIONADO" ||
              element.Formato !== "OK FORMATO") &&
            element.Terapeuta === cookies.get("name") &&
            element.Estado === "CUMPLIDA"
          ) {
            return element;
          }
        });
      }
      var totales = datos.filter((element) => {
        if (element.ID === cookies.get("idnum")) {
          return element;
        }
      });

      setTotal(totales);

      if (incompletos.length > 0) {
        setData(incompletos);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchallData();
  }, [month]);

  if (cookies.get("perms") === "therapist") {
    return (
      <>
        <h1>Pendientes</h1>
        <div className="Disclaimer">
          {total.map((data) => (
            <>
              <h2>
                Esta información va orientada a terapeutas domiciliarios sobre
                las sesiones cumplidas.
              </h2>
              <label>
                Total de sesiones con firma y evolución: {data.Completas}
              </label>
              <br />
              <label>
                Valor de sesiones con firma y evolución: {data.CompletasDinero}
              </label>
              <br />
              <label>
                Total de sesiones sin firma y/o evolución: {data.Pendiente}
              </label>
            </>
          ))}
        </div>
        <br />
        <select id="Mes" onChange={GetMonth}>
          <option>Septiembre</option>
          <option>Agosto</option>
          <option>Julio</option>
          <option>Junio</option>
          <option>Abril</option>
          <option>Mayo</option>
        </select>
        <div className="table_container"></div>
        <table className="tab_content">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Terapeuta</th>
              <th>Sitio</th>
              <th>Documento paciente</th>
              <th>Paciente</th>
              <th>Admision</th>
              <th>Firmado</th>
              <th>Evolucionado</th>
              <th>Formato</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((data, key) => (
              <tr key={key}>
                <td>{data.Fecha}</td>
                <td>{data.Hora}</td>
                <td>{data.Terapeuta}</td>
                <td>{data.Sitio}</td>
                <td>{data.DocumentoPaciente}</td>
                <td>{data.Paciente}</td>
                <td>{data.Admision}</td>
                <td className={data.Firmado}>{data.Firmado}</td>
                <td className={data.Evolucionado}>{data.Evolucionado}</td>
                <td className={data.Formato}>{data.Formato}</td>
                <td>
                  <Popup
                    trigger={
                      <button disabled={data.BotonFirma}>Reporte firma</button>
                    }
                  >
                    <select id="Novedad" onChange={GetNews}>
                      <option hidden>
                        Seleccione el tipo de novedad presentada
                      </option>
                      <option>
                        El sistema no me permite enviar la firma o aparece error
                      </option>
                      <option>El sistema no carga</option>
                      <option>
                        La firma no se registro durante el dia de la sesión
                      </option>
                      <option>
                        Equivocación al momento de realizar el registro
                      </option>
                    </select>
                    <br />
                    <br />
                    <button
                      onClick={() =>
                        AddNew(
                          data.Paciente,
                          data.DocumentoPaciente,
                          "Sistema de firmas de RiiE",
                          data.Fecha
                        )
                      }
                    >
                      Enviar
                    </button>
                  </Popup>
                  <Popup
                    trigger={
                      <button disabled={data.BotonEvolucion}>
                        Reporte evolución
                      </button>
                    }
                  >
                    <select id="Novedad" onChange={GetNews}>
                      <option hidden>
                        Seleccione el tipo de novedad presentada
                      </option>
                      <option>
                        La evolución no se registro el dia de la sesión
                      </option>
                      <option>
                        El sistema de medifolios se encuentra fuera de servicio
                      </option>
                      <option>
                        Equivocación al momento de realizar el registro
                      </option>
                    </select>
                    <br />
                    <br />
                    <button
                      onClick={() =>
                        AddNew(
                          data.Paciente,
                          data.DocumentoPaciente,
                          "Sistema de evoluciones de Medifolios",
                          data.Fecha
                        )
                      }
                    >
                      Enviar
                    </button>
                  </Popup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  } else {
    return (
      <>
        <h1>Pendientes</h1>
        <select id="Mes" onChange={GetMonth}>
          <option>Septiembre</option>

          <option>Agosto</option>
          <option>Julio</option>
          <option>Junio</option>
          <option>Mayo</option>
          <option>Abril</option>
        </select>
        <div className="table_container"></div>
        <table className="tab_content">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Terapeuta</th>
              <th>Sitio</th>
              <th>Documento paciente</th>
              <th>Paciente</th>
              <th>Admision</th>
              <th>Firmado</th>
              <th>Evolucionado</th>
              <th>Formato</th>
            </tr>
          </thead>
          <tbody>
            {data.map((data, key) => (
              <tr key={key}>
                <td>{data.Fecha}</td>
                <td>{data.Hora}</td>
                <td>{data.Terapeuta}</td>
                <td>{data.Sitio}</td>
                <td>{data.DocumentoPaciente}</td>
                <td>{data.Paciente}</td>
                <td>{data.Admision}</td>
                <td className={data.Firmado}>{data.Firmado}</td>
                <td className={data.Evolucionado}>{data.Evolucionado}</td>
                <td className={data.Formato}>{data.Formato}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
};

export default Tasks;
