import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./meetings.css";

const Meetings = () => {
  const [drawtable, setDrawTable] = useState([]);
  const [auxdrawtable, setAuxDrawTable] = useState([]);
  const cookies = new Cookies();
  const date = new Date("01/01/2023");
  const [filter, setFilter] = useState("");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const Next = () => {
    if (end > drawtable.length) {
      alert("No hay mas registros que mostrar");
    } else {
      setStart(start + 20);
      setEnd(end + 20);
    }
  };

  const Previous = () => {
    if (start === 0) {
      alert("No hay datos anteriores para mostrar");
    } else {
      setStart(start - 20);
      setEnd(end - 20);
    }
  };

  const InputHandler = (e) => {
    setFilter(e.target.value);
    Filter(e.target.value);
  };

  const Filter = (filterword) => {
    var busqueda = auxdrawtable.filter((element) => {
      if (
        element.Name.toString().toUpperCase().includes(filterword) ||
        element.Name.toString().toLowerCase().includes(filterword) ||
        element.PatientName.toString().toUpperCase().includes(filterword) ||
        element.PatientName.toString().toLowerCase().includes(filterword)
      ) {
        return element;
      } else {
        return "";
      }
    });
    setDrawTable(busqueda);
    setStart(0);
    setEnd(20);
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/1BakRTepMLFaGtr4ceV-2RUFkn3aJ8NFhbkk7vlE8fq0/values:batchGet",
        {
          params: {
            key: "AIzaSyBoE4hP1PbxiPE5BvP7FOJAescmdvjGIC0",
            ranges: "Data",
          },
        }
      );

      var formateddata = res.data.valueRanges[0].values
        .slice(2, res.data.valueRanges[0].values.length)
        .map((item) => {
          var color = "autorizado";
          var fecha = undefined;

          if (item[5] !== undefined) {
            let pieces = item[5].split("/");
            fecha = pieces[1] + "/" + pieces[0] + "/" + pieces[2];
          } else {
            fecha = "02/01/2023";
          }

          if (item[5] === "") {
            fecha = "02/01/2023";
          }

          if (item[11] === "NO AUTORIZADO") {
            color = "no-autorizado";
          } else if (item[11] === "AUTORIZADO") {
            color = "autorizado";
          } else if (item[11] === "CERRADO") {
            color = "cerrado";
          } else {
            color = "default-color";
          }

          return {
            PatientName: item[0],
            Area: item[2],
            PatientID: item[1],
            Convenio: item[3],
            Date: item[5],
            OrderDate: item[6],
            DateFilter: fecha,
            IsCheck: item[11],
            Name: item[4],
            Admission: item[7],
            TotalSessions: item[8],
            Sessions: item[9],
            AuthDate: item[10],
            Color: color,
            Agendadas: item[12],
            AdmState: item[13],
          };
        });

      var finaldata = formateddata.filter((item) => {
        if (item.Name !== "" && item.Name !== undefined) {
          return item;
        }
      });

      if (cookies.get("perms") === "therapist") {
        var therapistdata = finaldata.filter((item) => {
          if (
            item.Name === cookies.get("name") &&
            new Date(item.DateFilter) > date
          ) {
            return item;
          }
        });
        setDrawTable(therapistdata.reverse());
        setAuxDrawTable(therapistdata);
      } else {
        var supervisordata = finaldata.filter((item) => {
          if (new Date(item.DateFilter) > date) {
            return item;
          }
        });

        setDrawTable(supervisordata.reverse());
        setAuxDrawTable(supervisordata);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchallData();
  }, []);

  return (
    <>
      <div className="control_panel">
        <div className="filter">
          Filtro por Nombre:{" "}
          <input value={filter} onChange={InputHandler}></input>
        </div>
        <div className="buttons">
          <button onClick={Previous}>Anterior</button>
          <button onClick={Next}>Siguiente</button>
        </div>
      </div>
      <div className="table_container">
        <table className="tab_content">
          <thead>
            <tr>
              <th>Nombre del paciente</th>
              <th>Area</th>
              <th>Documento del paciente</th>
              <th>Convenio</th>
              <th>Fecha de la orden</th>
              <th>Fecha de asignación</th>
              <th>Número de admisión</th>
              <th>Total sesiones</th>
              <th>Sesiones prestadas</th>
              <th>Sesiones agendadas</th>
              <th>Autorizado</th>
              <th>Fecha de autorización</th>
              <th>Terapeuta</th>
              <th>Estado de la admision</th>
            </tr>
          </thead>
          <tbody>
            {drawtable.slice(start, end).map((asign, key) => (
              <tr key={key}>
                <td className="table-data">{asign.PatientName}</td>
                <td className="table-data">{asign.Area}</td>
                <td className="table-data">{asign.PatientID}</td>
                <td className="table-data">{asign.Convenio}</td>
                <td className="table-data">{asign.OrderDate}</td>
                <td className="table-data">{asign.Date}</td>
                <td className="table-data">{asign.Admission}</td>
                <td className="table-data">{asign.TotalSessions}</td>
                <td className="table-data">{asign.Sessions}</td>
                <td className="table-data">{asign.Agendadas}</td>
                <td className={asign.Color}>{asign.IsCheck}</td>
                <td className="table-data">{asign.AuthDate}</td>
                <td className="table-data">{asign.Name}</td>
                <td className="table-data">{asign.AdmState}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Meetings;
