import React from "react";

const Forms = () => {
  return (
    <>
      <h1>Formularios (en construcción)</h1>

      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeqZ9lONkyusCWgr4VJuIVEdxTZEjutf0-GokMIZfiikkHIiQ/viewform?embedded=true"
        width="100%"
        height="80%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Cargando…
      </iframe>
    </>
  );
};

export default Forms;
